export const ROUTE_EVENT_BOOKING_V2 = "eventBookingV2";
export const ROUTE_EVENT_BOOKING_FEEDBACK_PAGE_V2 = "eventBookingFeedbackV2";
export const ROUTE_REGISTRATION_SUCCESSFUL_PAGE = 'registrationSuccessPage';
export const ROUTE_TS_GTM_REGISTRATION = 'ts_gtm_registration';
export const ROUTE_THANK_YOU_FORM = 'thankyou';


export const ANALYTICS_KEYS_TO_REMOVE = ['workshopName','monthOfPregnancy','occupationDetail','selectedPlanId']


export const QUERY_PARAM_PAGE_TYPE = 'pagetype'
export const QUERY_PARAM_WHATSAPP_NUMBER = 'whatsAppNumber'
export const QUERY_PARAM_NAME = 'name'
export const QUERY_PARAM_WHATSAPP_GROUP = 'wag'
export const QUERY_PARAM_DIRECT = 'direct'